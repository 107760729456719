import React, { Fragment, useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Select, { components } from 'react-select';
import { Listbox, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid';
import axios from 'axios';
import ls from 'local-storage'; // Ensure you have this or use an alternative
import Context from 'views/Context/Context';
import { Container } from 'reactstrap';
import Connexion from 'Connexion';
import { BASE_URL, SECRET_KEY_ENCRYPT, Disconnected } from './../../utils/config';
import Joyride from 'react-joyride';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Form,
  Spinner,
  Alert,
} from "reactstrap";

// Import your SVG icons as React Components
import { ReactComponent as Person } from './icons/person.svg';
import { ReactComponent as Material } from './icons/material.svg';
import { ReactComponent as Integration } from './icons/integration.svg';
import { ReactComponent as Cm_en } from './icons/Cm_en.svg';
import { ReactComponent as Docs } from './icons/documentation.svg';
import { ReactComponent as Facturation } from './icons/facturation.svg';
import { ReactComponent as Reglage } from './icons/reglage.svg';
import { ReactComponent as Remise } from './icons/remise.svg';
import { ReactComponent as Stock } from './icons/stock.svg';
import { ReactComponent as Client } from './icons/client.svg';
import { ReactComponent as Catalogue } from './icons/catalogue.svg';
import { ReactComponent as FooterSvg } from './icons/footer.svg';
import { ReactComponent as Logo } from './icons/logo.svg';
import { ReactComponent as Tableau } from './icons/tableau.svg';
import { ReactComponent as Cm_enLigne } from './icons/cm_enligne.svg';
import { ReactComponent as Apps } from './icons/apps.svg';



// Import md5 and cryptr libraries
import md5 from 'md5'; // Install via npm: npm install md5
import Cryptr from 'cryptr'; // Install via npm: npm install cryptr

// Initialize Cryptr with your secret key
const cryptr = new Cryptr(SECRET_KEY_ENCRYPT);

// Custom styles for react-select
const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#f87171' : '#fecaca',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(248, 113, 71, 0.5)' : 'none',
    '&:hover': {
      borderColor: '#f87171',
    },
    borderRadius: '0.5rem',
    padding: '0.25rem',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#f87171'
      : state.isFocused
        ? '#fee2e2'
        : 'white',
    zIndex: "9999 !important",
    color: state.isSelected ? 'white' : '#374151',
    cursor: 'pointer',
    padding: '0.75rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:active': {
      backgroundColor: '#f87171',
      color: 'white',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // High value to ensure it appears above other elements
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9ca3af',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#374151',
    '&:hover': {
      color: '#f87171',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
const steps = [

  {
    target: '.ville',  // Ville selection
    content: 'Cliquez ici pour changer de ville et afficher uniquement les caisses associées à cette ville.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        padding: '12px',
        borderRadius: '8px',
      },
    },
  },
  {
    target: '.caisses-inactives',  // Caisses inactives
    content: 'Cliquez ici pour accéder à vos caisses inactives et les réactiver si nécessaire.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
      },
    },
  },
  {
    target: '.changer-caisse',  // Changer de caisse
    content: 'Cliquez ici pour sélectionner une autre caisse et y accéder.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
      },
    },
  },
  {
    target: '.deconnexion',  // Target for the 'Déconnexion' button
    content: 'Cliquez ici pour vous déconnecter du back-office.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',  // Tooltip background color
        color: '#fff',  // Tooltip text color
        fontSize: '16px',  // Text size for tooltip
        borderRadius: '8px',  // Tooltip border radius
        padding: '12px',  // Padding for tooltip
      },
    },
  },
  {
    target: '.mon-compte',  // Mon compte menu item
    content: 'Gérez vos informations personnelles : mot de passe, abonnements, cartes bancaires, et informations à afficher sur l’entête des documents PDF.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.tableau-de-bord',  // Tableau de bord
    content: 'Accédez au tableau de bord lié à la caisse sélectionnée pour suivre vos performances.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.catalogue',  // Catalogue
    content: 'Gérez le catalogue des produits associés à la caisse sélectionnée.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.gestion-des-privilèges',  // Gestion des privilèges
    content: 'Attribuez et gérez les privilèges des caissiers, serveurs et livreurs liés à la caisse sélectionnée.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.client',  // Client menu item
    content: 'Gérez vos clients et consultez leur historique de transactions associé à la caisse sélectionnée.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.regles-de-remise',  // Règles de remise menu item
    content: 'Définissez et automatisez des règles de remise applicables à vos produits ou services pour des promotions spécifiques, en fonction de la caisse sélectionnée.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.gestion-de-stock',  // Gestion de stock menu item
    content: 'Suivez et gérez vos stocks en temps réel : créez des bons de commande, des bons de production, gérez vos inventaires et surveillez les niveaux de stock associés à la caisse sélectionnée.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.facturation',  // Facturation / Devis menu item
    content: 'Gérez vos factures et devis : suivez les paiements et exportez vos documents comptables liés à la caisse sélectionnée.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.channel-manager',  // Channel Manager menu item
    content: 'Configurez les menus de vos différentes plateformes de commande (Bornety, site de commande en ligne, QR code) ainsi que vos services de livraison (Glovo, Lalivraison.ma).',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.integrations',  // Intégrations menu item
    content: 'Configurez et gérez les intégrations avec vos outils préférés : site de commande en ligne, Glovo, Bornety, gestion des avis clients, Trello, Kitchen Display, Client Display, et bien plus encore.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.materiel-de-caisse',  // Matériel de caisse menu item
    content: 'Commandez votre matériel de caisse directement depuis le back-office et profitez d’offres exceptionnelles.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.documentation',  // Documentation menu item
    content: 'Accédez à nos vidéos tutoriels et à notre documentation pour mieux comprendre les fonctionnalités proposées.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.actualites',  // Actualités & Nouveautés menu item
    content: 'Restez informé des dernières nouveautés et mises à jour de notre plateforme.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
  {
    target: '.nos-applications',  // Nos applications menu item
    content: 'Découvrez nos applications complémentaires : prise de commande serveur (iOS et Android), Kitchen Display, Client Display, et Caisse-Lytics pour le suivi de vos données en temps réel.',
    styles: {
      tooltip: {
        backgroundColor: '#f87171',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px',
      },
    },
  },
];


// Custom Option Component
const CustomOption = (props) => {
  const { data, isSelected, isFocused, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ zIndex: 9999 }}
      className={`flex flex-col p-2 ${isFocused ? 'bg-gray-100' : 'bg-white'
        } ${isSelected ? 'bg-red-100' : ''}`}
    >
      <div className="flex justify-between items-center w-full">
        <span className="font-semibold text-gray-900">{data.nom_societe}</span>
        {isSelected && <CheckIcon style={{ height: "20px", width: "20px" }} className="text-green-500" />}
      </div>
      <span className="text-sm text-gray-600">{data.email}</span>
      <span className="text-xs text-gray-500">Expiration: {data.date_fin}</span>
    </div>
  );
};

// Custom SingleValue Component
const CustomSingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <div className="flex flex-col">
        <span className="font-semibold text-gray-900">{data.nom_societe}</span>
        <span className="text-sm text-gray-600">{data.email}</span>
      </div>
    </components.SingleValue>
  );
};

const PointDeVente = (props) => {
  const [caisseList, setCaisseList] = useState([]);
  const [filteredCaisses, setFilteredCaisses] = useState([]);
  const [villes, setVilles] = useState([]);
  const [selectedVille, setSelectedVille] = useState(null);
  const [selectedPointDeVente, setSelectedPointDeVente] = useState(null);
  const [caissesStatus, setCaissesStatus] = useState('active');
  const [showError, setShowError] = useState(false);
  const [clickedMenuItem, setClickedMenuItem] = useState(null);
  const [runTour, setRunTour] = useState(false);
  const { history } = props;




  const menuItems = [
    { name: 'Mon compte', icon: Person, route: '/mon-compte', class: 'mon-compte' },
    { name: 'Tableau de bord', icon: Tableau, route: '/dashboard', class: "tableau-de-bord" },
    { name: 'Catalogue', icon: Catalogue, route: '/catalogue', class: "catalogue" },
    { name: 'Gestion des privilèges', icon: Reglage, route: '/privileges', class: "gestion-des-privilèges" },
    { name: 'Client', icon: Client, route: '/listClient', class: "client" },
    { name: 'Règles de remise', icon: Remise, route: '/regles-de-reduction', class: "regles-de-remise" },
    { name: 'Gestion de stock', icon: Stock, route: '/stock', class: "gestion-de-stock" },
    { name: 'Facturation / Devis', icon: Facturation, route: '/dashboardfactures', class: "facturation" },
    { name: 'Channel Manager', icon: Cm_en, route: '/channel-manager/menu', class: "channel-manager" },
    { name: 'Intégrations', icon: Integration, route: '/integrations', class: "integrations" },
    { name: 'Matériel de caisse', icon: Material, route: '/store-page', class: "materiel-de-caisse" },
    // { name: 'Avis client', icon: Reglage, route: '/notation' },
    // { name: 'Commandes en ligne', icon: Cm_en, route: '/Lalivraisonma' },
    { name: 'Documentaion', icon: Docs, route: '/Lalivraison.ma', class: "documentation" },
    { name: 'Actualités & Nouveautés', icon: Cm_enLigne, route: '/Blog', class: "actualites" },
    { name: 'Nos applications', icon: Apps, route: '/Applications', class: "nos-applications" },
  ];

  const { setSelectedOption_menu } = useContext(Context);

  // Fetch the list of Caisses (sales points)
  useEffect(() => {
    document.title = `Backoffice`;


    const fetchCaisses = async () => {
      try {
        const url = `${BASE_URL}caisse/listCaisse`;
        const config = {
          headers: {
            'Authorization': 'Bearer ' + ls.get('token'),
          },
        };
        const params = {
          email: ls.get('email'),
        };
        const response = await axios.post(url, params, config);
        const data = response.data.data || [];
        setCaisseList(data);

        // Initial filtering based on caissesStatus
        const initialFiltered = data.filter(
          (caisse) => caisse.alerte_paiement == (caissesStatus === 'active' ? 0 : 2)
        );
        console.log("initialFiltered", initialFiltered)
        setFilteredCaisses(initialFiltered);

        // Extract unique villes from the filtered caisses
        const villesData = [...new Set(initialFiltered.map((caisse) => caisse.ville))];
        console.log('villesData', villesData);
        setVilles(villesData);


        // No need to reset caissesStatus as it's already initialized
      } catch (error) {
        console.error('Error fetching caisses:', error);
      }

    };
    fetchCaisses();

    // ls.set('tokenizer', md5(caisseId.toString()));
    // ls.set('currentcaisse', cryptr.encrypt(caisseId.toString()));
    // ls.set('caissename', caisseName);
    // ls.set('emailCurrentCaisse', caisseEmail);
    // ls.set('datefin', cryptr.encrypt(dateFin));
    // ls.set('loaderbuttondiff', 0);


  }, []); // Added caissesStatus to dependencies to refetch on status change

  // Filter the caisses based on selected Ville and Status
  useEffect(() => {

    let filtered = [...caisseList];

    if (caissesStatus) {
      filtered = filtered.filter(
        (caisse) => caisse.alerte_paiement == (caissesStatus === 'active' ? 0 : 2)
      );

      const villesData = [...new Set(filtered.map((caisse) => caisse.ville))];
      setVilles(villesData);
    }

    if (selectedVille) {
      filtered = filtered.filter((caisse) => caisse.ville === selectedVille);
    }

    setFilteredCaisses(filtered);
    setSelectedPointDeVente(null); // Reset point de vente selection



    const encryptedCaisseId = ls.get('currentcaisse');
    const caisseName = ls.get('caissename');
    const caisseEmail = ls.get('emailCurrentCaisse');
    const encryptedDateFin = ls.get('datefin');



    if (encryptedCaisseId && caisseName && caisseEmail && encryptedDateFin) {
      try {
        const decryptedCaisseId = cryptr.decrypt(encryptedCaisseId);
        const decryptedDateFin = cryptr.decrypt(encryptedDateFin);
        const caisseId = parseInt(decryptedCaisseId, 10);

        const selectedCaisse = filtered.find((caisse) => caisse.id_caisse == caisseId);


        if (selectedCaisse) {
          setSelectedPointDeVente({
            label: `${selectedCaisse.email} - ${selectedCaisse.nom_societe} - ${selectedCaisse.date_fin}`,
            value: selectedCaisse.id_caisse,
            email: selectedCaisse.email,
            nom_societe: selectedCaisse.nom_societe,
            date_fin: selectedCaisse.date_fin,
          });
          setSelectedVille(selectedCaisse.ville);
        }
      } catch (error) {
        console.error('Error decrypting caisse data:', error);
      }
    }


    // Update villes based on the new filtered caisses

  }, [selectedVille, caisseList]);


  useEffect(() => {

    let filtered = [...caisseList];

    if (caissesStatus) {
      filtered = filtered.filter(
        (caisse) => caisse.alerte_paiement == (caissesStatus === 'active' ? 0 : 2)
      );
      const villesData = [...new Set(filtered.map((caisse) => caisse.ville))];
      setVilles(villesData);
    }

    if (selectedVille) {
      filtered = filtered.filter((caisse) => caisse.ville === selectedVille);
    }

    setFilteredCaisses(filtered);
    setSelectedPointDeVente(null); // Reset point de vente selection

    // Update villes based on the new filtered caisses



  }, [caissesStatus]);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    if (!hasSeenTour) {
      setRunTour(true);  // Show the tour if it's the first visit
      localStorage.setItem('hasSeenTour', 'true'); // Mark tour as seen
    }
  }, []);

  // Handle menu item click
  const handleMenuClick = (item) => {
    console.log("handleMenuClick" , item)

    if (item.name == "Documentaion") {
      window.open("https://documentation.lacaisse.ma/", "_blank")
      return
    }



    if (!selectedPointDeVente) {
      setShowError(true);
      setClickedMenuItem(item.name);

      // Scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // Remove the jiggle animation after it completes (500ms)
      setTimeout(() => {
        setClickedMenuItem(null);
      }, 500);

      // Optionally, hide the error message after some time (e.g., 3 seconds)

    } else {
      setSelectedOption_menu(item.name);
      localStorage.setItem('selectedOption', JSON.stringify(item.name));
      history.push(item.route);
    }
  };

  // Handle ville change
  const handleVilleChange = (selectedOption) => {
    ls.remove('currentcaisse');
    ls.remove('caissename');
    ls.remove('emailCurrentCaisse');
    ls.remove('datefin');

    setSelectedVille(selectedOption ? selectedOption.value : null);
  };

  // Handle point de vente change
  const handlePointDeVenteChange = (selectedOption) => {

    console.log("selectedOption", selectedOption)

    setShowError(false);
    setSelectedPointDeVente(selectedOption ? selectedOption : null);

    if (selectedOption) {
      handleClick(
        selectedOption.value,
        selectedOption.nom_societe,
        selectedOption.email,
        selectedOption.date_fin
      );
    }
  };

  // Handle caisses status change (active/inactive)
  const handleCaissesStatusChange = (selectedOption) => {
    ls.remove('currentcaisse');
    ls.remove('caissename');
    ls.remove('emailCurrentCaisse');
    ls.remove('datefin');
    setCaissesStatus(selectedOption ? selectedOption : 'active');
    setSelectedPointDeVente(null);
    setSelectedVille(null);
  };

  // handleClick function to manage local storage
  const handleClick = (caisseId, caisseName, caisseEmail, dateFin) => {

    caisse_master(caisseId);
    ls.set('tokenizer', md5(caisseId.toString()));
    ls.set('currentcaisse', cryptr.encrypt(caisseId.toString()));
    ls.set('caissename', caisseName);
    ls.set('emailCurrentCaisse', caisseEmail);
    ls.set('datefin', cryptr.encrypt(dateFin));
    ls.set('loaderbuttondiff', 0);

  };


  const caisse_master = (id) => {
    let url = BASE_URL + "affichercaisses/" + id;
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ls.get('token'),
        'X-identifier-account': (typeof (ls.get('current_account_caisse')) !== "undefined" && ls.get('current_account_caisse') !== null) ? cryptr.decrypt(ls.get('current_account_caisse')) : (Disconnected()),
        'X-identifier-val': id
      }
    };
    axios.post(url, {}, config)
      .then(response => {

        let master = response.data.master
        let count = response.data.count
        ls.set('caissecount', count);
        ls.set('caissemaster', master);

      })
      .catch(error => {
        console.log(error);
        ls.set('caissecount', 0);
        ls.set('caissemaster', id)

      });
  };

  const startTour = () => {
    setRunTour(true);  // Trigger the tour to start
  };

  const restartTour = () => {
    const tourCompleted = localStorage.getItem('tourCompleted');
    if (tourCompleted === 'true') {
      setRunTour(false);  // Reset the tour
      localStorage.removeItem('tourCompleted');  // Clear completion status
    }
    setRunTour(true);  // Start the tour again
  };

  const signOut = (e) => {
    e.preventDefault();
    Connexion.signout();
    window.location.replace("/login")
  }

  return (

    <div style={{ maxHeight: "100vh", overflow: "hidden" , }} className="flex flex-col justify-between">




<header className="w-full bg-white shadow-md py-4 px-6 flex flex-col md:flex-row md:justify-between md:items-center items-start space-y-4 md:space-y-0 mb-3">
      {/* Logo Section */}
      <div className="flex items-center w-full md:w-auto justify-between">
        <div className="ml-2">
          <h1 className="text-xl md:text-2xl font-bold text-red-700 leading-none">
            <Logo style={{ width: "170px" }} />
          </h1>
          <p className="text-sm md:text-md font-bold text-red-500 mt-1 md:mt-0">
            <span className="text-red-800">la caisse enregistreuse </span>réinventée pour vous
          </p>
        </div>
        {/* Mobile Menu Icon */}
        <div className="md:hidden">
          <Menu as="div" className="ml-3 relative">
            <div className="relative inline-block text-left">
              <Menu.Button
                className="inline-flex items-center px-4 py-2 rounded-full border focus:outline-none transition-colors duration-200 ease-in-out"
                style={{
                  borderColor: 'rgb(197,48,48)',
                  color: 'rgb(197,48,48)',
                }}
              >
                <span className="font-semibold">Backoffice</span>
                <svg
                  className="ml-2 h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  style={{ color: 'rgb(197,48,48)' }}
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white" style={{ zIndex: "99" }}>
                
                  <Menu.Item >
                    {({ active }) => (
                      <button
                        onClick={signOut}
                        className={`block px-4 py-2 text-sm rounded-t-lg ${active ? 'bg-red-50 text-red-700' : 'text-gray-700'}`}
                        style={{ color: active ? 'rgb(197,48,48)' : 'inherit' }}
                      >
                        Se déconnecter
                      </button>
                    )}
                  </Menu.Item>
              
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {/* Backoffice Button for Desktop */}
      <div className="hidden md:block">
        <Menu as="div" className="ml-3 relative">
          <div className="relative inline-block text-left">
            <Menu.Button
              className="inline-flex items-center px-4 py-2 rounded-full border focus:outline-none transition-colors duration-200 ease-in-out"
              style={{
                borderColor: 'rgb(197,48,48)',
                color: 'rgb(197,48,48)',
              }}
            >
              <span className="font-semibold">Backoffice</span>
              <svg
                className="ml-2 h-4 w-4"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{ color: 'rgb(197,48,48)' }}
              >
                <path d="M6 9l6 6 6-6"></path>
              </svg>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white" style={{ zIndex: "99" }}>
            
                <Menu.Item >
                  {({ active }) => (
                    <button
                      onClick={signOut}
                      className={`block px-4 py-2 text-sm rounded-t-lg ${active ? 'bg-red-50 text-red-700' : 'text-gray-700'}`}
                      style={{ color: active ? 'rgb(197,48,48)' : 'inherit' }}
                    >
                      Se déconnecter
                    </button>
                  )}
                </Menu.Item>
            
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>

      <Joyride
  steps={steps}
  continuous={true}
  showSkipButton={true}
  showProgress={true}
  run={runTour}  // Start the tour when runTour is true
  hideCloseButton={true}
  disableOverlay={false}
  spotlightClicks={true}
  callback={(data) => {
    console.log("data.status ", data.status);
    if (data.status === 'finished' || data.status === 'skipped') {
      setRunTour(false);  // Stop the tour after it is finished or skipped
      localStorage.setItem('tourCompleted', 'true');  // Mark tour as completed
    }
  }} // Stop tour when finished or skipped
  styles={{
    options: {
      zIndex: 1000,
      backgroundColor: '#f9fafb',
      arrowColor: '#f87171',
      primaryColor: '#f87171',
      textColor: '#2d3748',
      fontFamily: 'Arial, sans-serif',
    },
    tooltip: {
      fontSize: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
      padding: '15px',
      transition: 'all 0.3s ease-out',
    },
    button: {
      backgroundColor: '#f87171',
      borderRadius: '8px',
      color: '#fff',
      padding: '10px 20px',
      transition: 'background-color 0.2s ease',
      ':hover': {
        backgroundColor: '#d35f5f',
      },
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }}
  locale={{
    back: 'Précédent', // French for "Previous"
    next: 'Suivant', // French for "Next"
    skip: 'Passer', // French for "Skip"
    last: 'Terminer', // French for "Finish"
  }}
/>

<div className='overflow-scroll' style={{ transform: "scale(0.9)" }}>
<div className="container flex flex-col items-center flex-grow ">
        <div className="w-full max-w-7xl  p-6 py-8  bg-red-200 " style={{ borderRadius: "24px" }}>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center  mb-6 space-y-4 md:space-y-0">
            <div className='flex flex-col'>
              <h1 className="text-3xl font-bold text-gray-900 mb-2">Point de Vente</h1>
              <button onClick={() => { restartTour() }} className="custom-button_main">
                Tutoriel
              </button>
            </div>


            {/* Active/Inactive Listbox */}
            <Listbox value={caissesStatus} onChange={handleCaissesStatusChange} className="caisses-inactives">
              {({ open }) => (
                <div className="relative">
                  <div className="inline-flex shadow-sm rounded-md divide-x divide-gray-900">
                    <div className="relative z-0 inline-flex font-semibold items-center bg-gray-900 py-2 pl-3 pr-4 text-white rounded-l-md shadow-sm ">
                      <span>{caissesStatus === 'active' ? 'Caisse active' : 'Caisse inactive'}</span>
                    </div>
                    <Listbox.Button className="relative inline-flex items-center bg-gray-900 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                      <ChevronDownIcon style={{ width: '25px', height: '25px' }} className="text-white" aria-hidden="true" />
                    </Listbox.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5">
                      {['active', 'inactive'].map((status) => (
                        <Listbox.Option
                          key={status}
                          value={status}
                          className={({ active }) =>
                            `cursor-pointer select-none relative p-3 ${active ? 'bg-gray-800 text-white' : 'text-gray-900'
                            }`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                {status === 'active' ? 'Caisse active' : 'Caisse inactive'}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-gray-800'
                                    }`}
                                >

                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Ville Dropdown */}
            <div>
              <label className="block text-md font-semibold text-red-900 mb-2" htmlFor="ville-select">
                Ville
              </label>
              <div className="ville" >
                <Select
                  id="ville-select"
                  options={villes.map((ville) => ({ label: ville, value: ville }))}
                  onChange={handleVilleChange}
                  value={selectedVille ? { label: selectedVille, value: selectedVille } : null}
                  styles={customSelectStyles}
                  placeholder="Sélectionnez une ville..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>

            </div>

            {/* Point de Vente Dropdown */}
            <div className='changer-caisse'>
              <label className="block text-md font-semibold text-red-900 mb-2" htmlFor="pdv-select">
                Point de Vente
              </label>
              <Select
                id="pdv-select"
                options={filteredCaisses.map((caisse) => ({
                  label: `${caisse.email} - ${caisse.nom_societe} - ${caisse.date_fin}`,
                  value: caisse.id_caisse,
                  email: caisse.email,
                  nom_societe: caisse.nom_societe,
                  date_fin: caisse.date_fin,
                }))}
                onChange={handlePointDeVenteChange}
                value={selectedPointDeVente}
                styles={customSelectStyles}
                placeholder="Sélectionnez un point de vente..."
                className="react-select-container"
                classNamePrefix="react-select"
                components={{
                  Option: CustomOption,
                  SingleValue: CustomSingleValue,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Error Message */}
      {showError && (
        <div className='flex justify-center'>
          <div className="w-full max-w-5xl mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
            <p>🚫 Vous devez sélectionner votre point de vente avant de continuer.</p>
          </div>
        </div>

      )}

      {/* Menu Items */}

      <div className='flex justify-center '>
        <div className="w-full  max-w-6xl grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-5 mt-8 p-2 overflow-auto"

        >
          {menuItems.map((item) => (
            <div
              key={item.name}
              onClick={() => handleMenuClick(item)}

              className={`menu_item_global flex flex-col items-center p-4 rounded-lg cursor-pointer ${item.class}  ${clickedMenuItem === item.name ? 'animate-jiggle' : ''
                }`}
            >
              <div
                style={{ width: '90px', height: '90px', border: '#f87171 solid 1px', borderRadius: '10px' }}
                className="flex justify-center items-center bg-red-200 mb-2"
              >
                <item.icon style={{ height: "65px", width: "65px" }} />
              </div>
              <span className="mt-2 text-center font-bold text-gray-900" style={{ fontSize: "17px", width: "150px" }}>{item.name}</span>
            </div>
          ))}
        </div>

      </div>

</div>

      




      <footer className="w-full absolute bottom-0 inset-x-0" style={{ zIndex: "-2" }}>
        <FooterSvg alt="Footer" className="w-full h-auto" />
      </footer>

    </div>

  );
};

export default withRouter(PointDeVente);
