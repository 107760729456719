
import React, { useState , useEffect }from "react";
import Context from "./Context";
import _ from "lodash";
import deepdash from "deepdash";
import ls from "local-storage"
import { SECRET_KEY_ENCRYPT , Disconnected , BASE_URL} from '@utils/config'
import Cryptr from 'cryptr'
import axios from "axios"
import moment from "moment"
//import Context from 'views/Context/Context'

const Provider = (props) => {

  
 
  const [isYearlyPaying , setIsYearlyPaying] = useState(12)
  const [isGestionStock , setIsGestionStock] = useState(null)
  const [isFacturation , setIsFacturation] = useState(null)
  const [isPriseDeCommande , setIsPriseDeCommande] = useState(null)
  const [nbrTablete,setNbrTablet] = useState(0)
  const [nbrSousCompte , setNbrSousCompte] = useState(0)
  const [selectedEntreprise , setSelectedEntreprise] = useState({})

  const [uuidInvoiceToPay , setUuidInvoiceToPay] = useState({})
  const [idCiasse,setIdCaisse] = useState()
  const [dateFin , setDateFin ] = useState()
  const [dateDebut , setDateDebut] = useState()
  const [isFreshCaisse , setIsFreshCaisse] = useState()
  const [msgChooseEntre , setMsgChooseEntre] = useState(null)
  const [caisse,setCaisse] = useState()
  const [nbrSCInitial , setNbrSCInitial] = useState(0)
  const [nbrTBInitial , setNbrTBInial ] = useState(0)
  const [facturationInitial,setInitialFacturation] = useState(true)
  const [isPriseDeCommandeInitial , setIsPriseDeCommandeInitial] = useState(true)
  const [gestionStockInitial , setGestionStockInitial] = useState(true)
  const [leftMonths , setLeftMonths] = useState()
  const [nbrMonth , setNbrMonth] = useState(1)
  const [apiPayment , setApiPayment] = useState(0)
  const [nbrBorne , setNbrBorne] = useState(0)

  const [apiActive , setApiAactive] = useState(0)
  const [isApi , setIsApi ] = useState(false)

  const [wtspActive , setWtspActive] = useState(0)
  const [isWtsp , setIsWtsp ] = useState(false)

  const [nbBorneInitialContext , setNbBorneInitialContext] = useState(0)


  //ecom States

  const [ panelContext, setPanelContext]  = useState([])
  const [selectedAdresse , setSelectedAdresse] = useState({})
  const [selectedEntrepriseStore , setSelectedEntrepriseStore] = useState({})
  const [msgChooseAdressse , setMsgChooseAdressse] = useState(null)
  const [openPanel , setOpenPanel] = useState(false)

  // menu 

  const [selectedOption_menu , setSelectedOption_menu ] = useState(null)




  return (
    <Context.Provider
      value={{
        nbrSCInitial,
        setNbrSCInitial,
        nbrTBInitial , setNbrTBInial,
        facturationInitial,setInitialFacturation,
        gestionStockInitial , setGestionStockInitial,
        isYearlyPaying,
        setIsYearlyPaying,
        isGestionStock,
        setIsGestionStock,
        isFacturation,
        setIsFacturation,
        nbrTablete,
        setNbrTablet,
        nbrSousCompte,
        setNbrSousCompte,
        selectedEntreprise,
        setSelectedEntreprise,
        idCiasse,
        setIdCaisse,
        uuidInvoiceToPay , 
        setUuidInvoiceToPay,
        dateFin , 
        setDateFin ,
        dateDebut , 
        setDateDebut,
        isFreshCaisse,
        setIsFreshCaisse,
        msgChooseEntre,
        setMsgChooseEntre,
        setCaisse,
        caisse,
        leftMonths ,
        setLeftMonths,
        panelContext,
        setPanelContext,
        selectedAdresse,
        setSelectedAdresse,
        selectedEntrepriseStore ,
        setSelectedEntrepriseStore,
        msgChooseAdressse,
        setMsgChooseAdressse,
        openPanel , setOpenPanel,
        nbrMonth , setNbrMonth,
        apiPayment , setApiPayment,
        nbrBorne , setNbrBorne ,
        apiActive , setApiAactive ,
        isApi , setIsApi ,
        wtspActive , setWtspActive,
        isWtsp , setIsWtsp,
        nbBorneInitialContext , setNbBorneInitialContext ,
        isPriseDeCommande , setIsPriseDeCommande ,
        isPriseDeCommandeInitial , setIsPriseDeCommandeInitial ,

        selectedOption_menu , setSelectedOption_menu 

      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default Provider;