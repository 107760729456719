import React, { Component, createContext } from 'react';
import "./index.css"
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';

import axios from "axios";
import AppProvider from 'views/Context/Context';
import ProviderC from 'views/Context/Provider';

import Connexion from "./Connexion";
import { lstat } from 'fs';
import ls from 'local-storage';
import ProviderPriviliges from "views/Context/ProviderPriviliges"

import FloatingButton from "./AssistanceButton"
import Maintenance from 'views/Maintenance/maintenance';

import "views/MonCompte/tailwind/tailwind.min.css"


import MenuOption from "views/menuOptions/menuOptions"



const loading = () => <div className="animated fadeIn pt-3 text-center">Chargement en cours...
<br />
    Déconnectez-vous de votre compte si le chargement est très lent</div>

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});



const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});



const Error404 = Loadable({
  loader: () => import('./views/Maintenance/error_404'),
  loading
});
const Error428 = Loadable({
  loader: () => import('./views/Maintenance/error_428'),
  loading
});


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {

    return ls.get('connected') === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  }} />
);


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalCurrentCaisse: 0,
    };

  }





  render() {
    return (
      // <ProviderPriviliges>
      <ProviderC>
       
      
        < BrowserRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props}
              isAuthed={Connexion.isAuthenticated}
              fnAuth={Connexion.authenticate}
              fnLogOut={Connexion.signout} />}
            />

            
            <Route exact path="/underMaintenance" component={Maintenance} />
            <Route exact path="/logout" name="Se déconnecter" component={Register} />
            <Route exact path="/maintenance" component={Error404} />
            <Route exact path="/menuOption" component={MenuOption } />
            <Route exact path="/toomanyrequests" component={Error428} />
           <PrivateRoute path='/' name="Home" component={DefaultLayout} />
         

       
          </Switch>
        </ BrowserRouter>


      <FloatingButton showButton={true} handleClick={()=>{window.location.replace("/reclamation")}}/>
      
      </ProviderC>
      // </ProviderPriviliges>
    );
  }
}

export default App;